@import './styles/settings';
@import './styles/base';

@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot?5tyga4');
  src: url('./fonts/icomoon.eot?5tyga4#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?5tyga4') format('truetype'),
    url('./fonts/icomoon.woff?5tyga4') format('woff'),
    url('./fonts/icomoon.svg?5tyga4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-S-icon-02:before {
  content: '\e900';
  font-size: 4rem;
  color: $red-color;
}

.page {
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: auto;
  // border: 1px solid grey;
}

.page-right {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 72px 1fr;
  background: linear-gradient(
      to right,
      rgba(27, 34, 38, 0.9),
      rgba(27, 34, 38, 0.85)
    ),
    url('/img/header-image.jpg');
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
  // border: 1px solid grey;

  &__scroll {
    display: grid;
    align-content: center;
    // border: 1px solid grey;
  }

  &__nav {
    display: grid;
    align-self: end;
  }

  &__body {
    display: grid;
    align-items: center;
  }
}

.content {
  display: grid;
  animation-name: moveInBottom;
  animation-duration: 2s;
  // border: 1px solid grey;
}

.content--side-margin {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  // border: 1px solid grey;
}

.header__title {
  display: block;
  @include font-styles($white-color, $extra-large, $semi-bold);
}

.red-text {
  color: $red-color;
}

.underline-text {
  text-decoration: underline;
}

.header__subtitle {
  display: block;
  @include font-styles($light-grey-color, $large, $semi-bold);
}

.header__subtitle--small {
  @include font-styles($light-grey-color, $smaller, $regular);
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .page {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    // border: 1px solid grey;
  }

  .page-right {
    &__body {
      padding-top: $m-space;
    }
  }

  .content {
    padding-bottom: $l-space;
    // border: 1px solid grey;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  // font-size
  $mini: 0.8rem;
  $smaller: 1.3rem;
  $small: 1.6rem;
  $medium: 2.4rem;
  $large: 2.8rem;
  $larger: 3.2rem;
  $extra-large: 4.5rem;

  .icon-S-icon-02:before {
    content: '\e900';
    font-size: 3.2rem;
    color: $red-color;
  }

  .content {
    padding-bottom: $xxl-space;
    // border: 1px solid grey;
  }

  .header__title {
    @include font-styles($white-color, $extra-large, $semi-bold);
  }

  .header__subtitle {
    @include font-styles($light-grey-color, $large, $semi-bold);
  }

  .header__subtitle--small {
    @include font-styles($light-grey-color, $smaller, $regular);
  }
}
