@import '../../styles/settings';

.works {
  h1 {
    line-height: $xl-space;
  }
}

.content__works {
  display: grid;
  grid-template-columns: 190px 1fr;
  grid-template-rows: 400px;
  margin-top: $m-space;
  // border: 1px solid grey;
}

.project__tabs {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(7, 1fr);
  cursor: pointer;
  // border: 1px solid grey;
}

.project__tab {
  display: grid;
  align-items: center;
  padding-left: $xs-space;
  padding-right: $xs-space;

  @include font-styles($transparent-white, $smaller, $regular);
  // border: 1px solid grey;
}

.projects__display {
  display: grid;
  grid-template-columns: 1fr minmax(180px, auto);
  grid-template-rows: 1fr;
  column-gap: $xs-space;
  padding: $s-space $m-space;
  background: $transparent-white;
  // border: 1px solid grey;

  img {
    width: 390px;
    justify-self: center;
  }
}

.active {
  border-left: 5px solid $red-color;
  background: $transparent-white;
  @include font-styles($red-color, $smaller, $semi-bold);
  transition: all 0.5s ease-in-out;
}

.non-active {
  border-left: 5px solid $transparent-white;
}

.project__details {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto 1fr;

  h1 {
    line-height: $m-space;
    @include font-styles($white-color, $small, $bold);
  }

  .technologies {
    display: inline;
  }

  span {
    @include font-styles($light-grey-color, $mini, $regular);
  }

  hr {
    border: 1px solid $light-grey-color;
  }

  p {
    padding-top: $s-space;
  }

  .icons--2 {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: $s-space;
    font-size: $small;
    justify-self: end;
    align-self: end;
  }
}

@media only screen and (max-width: 950px) {
  .content__works {
    grid-template-rows: 640px;
    // border: 1px solid grey;
  }

  .projects__display {
    grid-template-columns: 1fr;
    grid-template-rows: 400px 200px;
    // border: 1px solid grey;

    img {
      margin-bottom: $xs-space;
    }
  }
}

@media only screen and (max-width: 750px) {
  .content__works {
    grid-template-columns: 1fr;
    grid-template-rows: auto minmax(350px, auto);
    justify-items: center;
    // border: 1px solid grey;
  }

  .project__tabs {
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto;
    width: 400px;
    overflow: scroll;
    // border: 1px solid grey;
  }

  .project__tab {
    padding: $xxs-space $xs-space;
    text-align: center;
    // border: 1px solid grey;
  }

  .projects__display {
    grid-template-columns: 1fr;
    grid-template-rows: 350px 240px;
    width: 400px;
    justify-items: center;
    // border: 1px solid grey;

    img {
      width: 350px;
    }
  }

  .active {
    border-left: none;
    border-top: 5px solid $red-color;
  }

  .non-active {
    border-left: none;
    border-top: 5px solid $transparent-white;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  // font-size
  $mini: 0.8rem;
  $smaller: 1.3rem;
  $small: 1.6rem;
  $medium: 2.4rem;
  $large: 2.8rem;
  $larger: 3.2rem;
  $extra-large: 4.5rem;

  .project__tabs {
    width: 290px;
    // border: 1px solid grey;
  }

  .project__tab {
    padding: $xxs-space $xxs-space;
    @include font-styles($transparent-white, $smaller, $regular);
    // border: 1px solid grey;
  }

  .projects__display {
    width: 290px;
    grid-template-rows: 220px 220px;
    // border: 1px solid grey;

    img {
      width: 220px;
    }
  }

  .active {
    @include font-styles($red-color, $smaller, $semi-bold);
  }

  .project__details {
    h1 {
      line-height: $s-space;
      @include font-styles($white-color, $small, $bold);
    }

    span {
      @include font-styles($light-grey-color, $mini, $regular);
    }

    .icons--2 {
      font-size: $small;
    }
  }
}
