@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './settings';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1.8;
  font-family: 'Poppins', sans-serif;
  background: $dark-grey-color;
  @include font-styles($white-color, $smaller, $light);

  a {
    text-decoration: none;
    color: $light-grey-color;
  }

  div {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style-type: none;
  }
}

@media only screen and (max-width: 500px) {
  $smaller: 1.3rem;

  body {
    line-height: 1.8;
    font-family: 'Poppins', sans-serif;
    background: $dark-grey-color;
    @include font-styles($white-color, $smaller, $light);
  }
}
