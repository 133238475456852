// Colors
$dark-grey-color: #1b2226;
$medium-grey-color: #404146;
$light-grey-color: #a6a6a6;
$white-color: #ffffff;
$red-color: #ed4b4b;
$transparent-white: rgba(255, 255, 255, 0.2);

// Font Size
$mini: 1.1rem;
$smaller: 1.5rem;
$small: 2.4rem;
$medium: 3.2rem;
$large: 4rem;
$larger: 5.6rem;
$extra-large: 7.2rem;

// Font Weight
$extra-light: 200;
$light: 300;
$regular: 400;
$semi-bold: 500;
$bold: 600;

@mixin font-styles($color, $size, $weight) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
}

// Spacing
$xxs-space: 0.8rem;
$xs-space: 1.6rem;
$s-space: 2.4rem;
$m-space: 3.2rem;
$l-space: 4.8rem;
$xl-space: 6.4rem;
$xxl-space: 9.6rem;

// Media query
$tablet-breakpoint: 1180px;
$mobile-breakpoint: 500px;
